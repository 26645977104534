<template>
    <div class="wrapper_detail"
         v-if="this.page_settings"
    >

        <loading v-if="!this.$store.getters.loading" />

        <headerBg data-aos-duration="2000" data-aos-delay="200" data-aos="fade-up" :white="true"
            :subtitle="data.product.sub_title" :title="data.product.title" :description="data.product.short_description"
            :down="true" :bg="data.product.media.background[0].default" />
        <div class="detail" data-aos="zoom-in">
            <div class="info_intro">

                <p class="subtitle">{{data.product.sub_title}}</p>
                <h3 class="title" v-html="data.product.title"></h3>
                <br>
                <p class="description" v-html="data.product.description"></p>

                <div class="btnSafety">
                    <button class="safety" @click="save(data.product.media.pdf_1[0].default)">

                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.944 6.112C16.507 2.67 13.56 0 10 0C7.244 0 4.85 1.61 3.757 4.149C1.609 4.792 0 6.82 0 9C0 11.657 2.089 13.815 4.708 13.971V14H15.99V13.997L16 14C18.206 14 20 12.206 20 10C19.9988 9.10352 19.6971 8.23332 19.1431 7.5285C18.5891 6.82368 17.8148 6.32494 16.944 6.112ZM6 7H9V4H11V7H14L10 12L6 7Z"
                                fill="white" />
                        </svg>


                        <p>{{data.header_footer.vocabulary.safety_btn}}</p>
                    </button>
                    <br>
                    <button class="specification" @click="save(data.product.media.pdf_2[0].default)">

                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16.944 6.112C16.507 2.67 13.56 0 10 0C7.244 0 4.85 1.61 3.757 4.149C1.609 4.792 0 6.82 0 9C0 11.657 2.089 13.815 4.708 13.971V14H15.99V13.997L16 14C18.206 14 20 12.206 20 10C19.9988 9.10352 19.6971 8.23332 19.1431 7.5285C18.5891 6.82368 17.8148 6.32494 16.944 6.112ZM6 7H9V4H11V7H14L10 12L6 7Z"
                                fill="#00C0C7" />
                        </svg>
                        <p>{{data.header_footer.vocabulary.specification_btn}}</p>
                    </button>
                    <br>
                    <button class="archive" @click="save(data.product.media.archive[0].default)">

                        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M16.944 6.112C16.507 2.67 13.56 0 10 0C7.244 0 4.85 1.61 3.757 4.149C1.609 4.792 0 6.82 0 9C0 11.657 2.089 13.815 4.708 13.971V14H15.99V13.997L16 14C18.206 14 20 12.206 20 10C19.9988 9.10352 19.6971 8.23332 19.1431 7.5285C18.5891 6.82368 17.8148 6.32494 16.944 6.112ZM6 7H9V4H11V7H14L10 12L6 7Z"
                                    fill="#00C0C7" />
                        </svg>
                        <p>{{data.header_footer.vocabulary.archive_btn}}</p>
                    </button>
                </div>

                <p class="description" v-html="data.product.full_description"></p>

            </div>

            <carusel-wrapper :title="data.header_footer.vocabulary.other_product" :slides-per-view="3" data-aos="zoom-in" :styleArrow="true">

                <carusel-item data-aos-duration="2000" data-aos-delay="200" data-aos="zoom-in"
                    v-for="(productSlide, i) in data.all_products" :key="productSlide[i]">
                    <product-item :translate="15" :img="productSlide.media.icon[0].default" :title="productSlide.title"
                        :description="productSlide.short_description"
                        :btn-title="data.header_footer.vocabulary.btn_read_more"
                        :btn-link="'/' + locale + data.header_footer.menu[2].slug + '/' + productSlide.slug" />
                </carusel-item>

            </carusel-wrapper>

        </div>
    </div>
</template>

<script>
    import loading from '../components/loading'
    import {
        RepositoryFactory
    } from "../api/RepositoryFactory";
    const GetRepository = RepositoryFactory.get('data');

    import headerBg from "../components/app/headerBg";
    import CaruselWrapper from "../components/carusel/caruselWrapper";
    import CaruselItem from "../components/carusel/caruselItem";
    import ProductItem from "../components/product/productItem";
    export default {
        name: "ProductDeatail",
        components: {
            ProductItem,
            CaruselItem,
            CaruselWrapper,
            headerBg,
            loading
        },
        locale: window.localStorage.getItem('locale'),
        async mounted() {
            await this.fetch();
        },
        data() {
            return {
                isLoading: false,
                route: '1',
                data: {},
                page_settings: '',
                title: null,
                meta: [],
                og: [],
                locale: window.localStorage.getItem('locale')
            }
        },

        methods: {
            fetch() {
                this.$store.dispatch('SET_LOADING_AXIOS', 2000)
                return new Promise((resolve, reject) => {
                    GetRepository.getProductsItem(this.$route.params.slug)
                        .then(resp => {
                            this.isLoading = false;
                            this.data = resp.data.data;
                            this.page_settings = resp.data.data.page_settings;
                            this.title = this.data.meta.meta_title;
                            this.og = this.data.meta.og.meta;
                            resolve(resp);
                        })
                        .catch(err => {
                            reject(err);
                        });
                });
            },
            save(link) {
                let links = link;
                window.open(links, '_blank');
            }
        },

        watch: {
            '$route.params.slug': {
                immediate: true,
                handler() {
                    this.fetch()
                },
            },
        },

        metaInfo() {
            return {
                title: this.title,
                meta: this.og
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "../assets/scss/index";

    .detail {
        @include sectionGlobal;

        & .item_intro {
            &:hover {
                transform: translateY(-10px) !important;
            }
        }

        & .info_intro {
            padding: 10px 25px;


            & .subtitle {
                text-align: left !important;
                @include subtitle ($colorLightTitle);
            }

            & .subtitle_black {
                text-align: left !important;
                font-size: 24px !important;
                line-height: 1 !important;
                @include subtitle ($base-color);
            }

            & .title {
                max-width: 100% !important;
                text-align: left !important;
                margin: 20px 0 !important;
                @include title
            }

            & .description {
                max-width: 100% !important;
                margin: 0 !important;
                @include description
            }

            & .btnSafety {
                display: flex;
                justify-content: flex-start;
                margin: 80px 0;

                @media (max-width: 992px) {
                    margin: 40px 0;
                }

                @media (max-width: 425px) {
                    margin: 0 0 50px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                & .safety {
                    margin-right: 20px;
                    @include btnSafety($colorLinkActive, white, $colorLinkHover);

                    @media (max-width: 425px) {
                        margin: 0 !important;
                    }

                    & p {
                        @include Safety(white);
                    }
                }

                & .specification {
                    margin-left: 20px;
                    @include btnSafety(white, $colorLinkActive, $colorLinkHover);

                    @media (max-width: 768px) {
                        margin-left: 0;
                    }

                    @media (max-width: 425px) {
                        margin: 0 !important;
                    }

                    & p {
                        @include Safety($colorLinkActive);

                        &:hover {
                            color: white !important;
                        }
                    }
                }

                & .archive {
                    margin-left: 20px;
                    width: auto !important;
                    display: inline-flex !important;
                    padding: 0 20px !important;
                    @include btnSafety(white, $colorLinkActive, $colorLinkHover);

                    @media (max-width: 768px) {
                        width: 135px !important;
                    }

                    @media (max-width: 425px) {
                        margin: 0 !important;
                    }

                    & p {
                        @include Safety($colorLinkActive);
                        margin-left: 15px !important;

                        &:hover {
                            color: white !important;
                        }
                    }
                }
            }
        }
    }
</style>
